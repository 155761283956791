import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface ButtonProps {
  text: string;
  size?: 'sm' | 'md' | 'lg';
  type?: string;
  icon?: any;
  isLoading?: boolean;
  onClick?: React.ReactEventHandler;
  disabled?: boolean;
  isDanger?: boolean;
  color?:
    | 'main'
    | 'light'
    | 'dark'
    | 'warning'
    | 'success'
    | 'error'
    | 'stone'
    | 'inactive'
    | 'cancel';
}

const Button = (
  props: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  const color = props.color || 'main';
  const classNames = classnames({
    'rounded-full text-white shadow-md sans-serif focus:outline-none': true,
    'py-1 px-4 font-medium text-sm': props.size === 'sm',
    'py-4 px-8 font-semibold': props.size === 'md',
    'py-4 px-12 font-semibold': props.size === 'lg',
    'py-2 px-6 font-semibold': !props.size,
    'opacity-50': props.disabled,
    // 'bg-gradient text-blue-100': !props.isDanger,
    'bg-red-600 text-red-100': props.isDanger,
    'bg-gradient text-blue-100 focus:ring-primary  enabled:hover:bg-primary_dark text-white':
      color === 'main',
    'bg-primary_light focus:ring-primary enabled:hover:bg-sky-200 text-sky-600 ':
      color === 'light',
    'bg-stone-600/10 focus:ring-stone-600 enabled:hover:bg-stone-600/20 text-stone-600':
      color === 'stone',
    'bg-secondary focus:ring-secondary enabled:hover:bg-secondary_dark text-white':
      color === 'dark',
    'bg-error_light focus:ring-error enabled:hover:bg-red-200 text-error':
      color === 'error',
    'bg-green-600 focus:ring-green-700 enabled:hover:bg-green-300 text-green-100':
      color === 'success',
    'bg-warning_light focus:ring-warning enabled:hover:bg-amber-300 text-warning':
      color === 'warning',
    'bg-secondary_light focus:ring-secondary enabled:hover:bg-stone-300 text-secondary hover:text-secondary_dark':
      color === 'cancel',
    'bg-stone-50 focus:ring-stone-100 hover:bg-stone-100 text-stone-300 hover:text-stone-300  focus:text-stone-300':
      color === 'inactive',
    [props.className as string]: props.className
  });
  return (
    <button
      type={props.type}
      className={classNames}
      onClick={!props.isLoading ? props.onClick : () => null}
      disabled={props.disabled}
    >
      {props.isLoading ? (
        <>
          <FontAwesomeIcon icon={faCircleNotch} className="mr-2" spin />
          <span className="sans-serif">Loading...</span>
        </>
      ) : (
        <>
          {props.icon && <FontAwesomeIcon icon={props.icon} className="mr-2" />}
          <span className="sans-serif">{props.text}</span>
        </>
      )}
    </button>
  );
};

export default Button;
